import React from 'react';
import '../Styles/Services.css'; // Assuming you have a separate CSS file for Services.
import lawnmowingimage from '../Assets/ServicesPage/LawnMowing.jpg'
import hedgetrimmingimage from '../Assets/ServicesPage/HedgeTrimming.jpg'
import wasteremovalimage from '../Assets/ServicesPage/WasteRemoval.jpg'
import gardeningimage from '../Assets/ServicesPage/Gardening.jpg'

function Services() {
    return (
        <div className="services-section">
            <h2>Our Services</h2>
            <h3>From Katikati to Waihi: Cutz You Desire, Costz You Admire!</h3>
            <div className="service-card-section">
                <div className="service-card">
                    <img src={lawnmowingimage} alt="Lawn Mowing"/>
                    <h3>Lawn Mowing</h3>
                </div>
                <div className="service-card">
                    <img src={hedgetrimmingimage} alt="Hedge Trimming"/>
                    <h3>Hedge Trimming</h3>
                </div>
                <div className="service-card">
                    <img src={wasteremovalimage} alt="Waste Removal"/>
                    <h3>Waste Removal</h3>
                </div>
                <div className="service-card">
                    <img src={gardeningimage} alt="Gardening"/>
                    <h3>Gardening</h3>
                </div>
            </div> 
        </div>
    );
}

export default Services;
