import React from 'react';

function ContactLeftComponent() {
    return(
        <div className="contact-left-section">
            <div className="contact-details">
                <p>Please phone Marley's Mowing on <strong>0210676847</strong></p>
                <p>We answer quickly. Our team is waiting to take your call:</p>
                <ul>
                    <li>Mon-Fri: 8am-10pm</li>
                    <li>Sat: 9am-5pm</li>
                    <li>Sun: 10am-3pm</li>
                </ul>

                <p>You can also book a quote online, using our form. Online requests are normally responded to within 2-4 business hours.</p>
            </div>
            <div className="phoneaddress-details">
                <h2>Email Address:</h2>
                <p>MarleysMowingServices@gmail.com</p>
                <h2>Phone:</h2>
                <p>0210676847</p>
            </div>
        </div>
    );
    
}

export default ContactLeftComponent;