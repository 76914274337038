// src/components/Footer.js

import React from 'react';
import { Link } from 'react-router-dom'; // Make sure you're using React Router for this
import '../Styles/Footer.css'; // Assuming you will create a separate CSS file for the footer

function Footer () {
  return (
    <footer className="site-footer">
      <div className="footer-content">
        <ul className="footer-links">
          {/* Add more <li> tags for additional links as needed */}
          <li><Link to="/">Home</Link></li>
          <li><Link to="/services">Services</Link></li>
          <li><Link to="/aboutus">About Us</Link></li>
          <li><Link to="/booking">Book Now</Link></li>
          <li><Link to="/privacypolicy">Privacy Policy</Link></li>
        </ul>
        <div className="footer-contact">
          <p>Contact us at:</p>
          <p>Email: marleysmowingservices@gmail.com</p>
          <p>Phone: 0210676847</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
